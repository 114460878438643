*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body,
body.swal2-height-auto {
    height: 100% !important;
}

body.haDRvFEPwku3rMzaWUbqTQ {
    background: #fff;
    background-size: cover;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    line-height: 1.3;
    overflow: hidden;
    height: 100% !important;
}

body.haDRvFEPwku3rMzaWUbqTQ.swal2-height-auto {
    height: 100% !important;
}

.haDRvFEPwku3rMzaWUbqTQ {
    .bg {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        -webkit-filter: blur(80px);
        filter: blur(80px);
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    .message-box .btn-emoji {
        border-radius: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 43px;
    }

    .message-box .btn-emoji>img {
        max-width: 100%;
    }

    .online-bullet {
        background: #27bf4a;
        border-radius: 50%;
        content: "";
        display: inline-block;
        height: 13px;
        width: 13px;
        right: 0;
    }

    span.chat-message-counter {
        background: #020202;
        border: 2px solid #000000;
        color: red;
        border-radius: 50%;
        display: none;
        font-size: 12px;
        font-weight: 800;
        height: 28px;
        left: 0;
        line-height: 28px;
        margin: -1px 0 0 0px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 28px;
    }

    /*--------------------
      Chat
      --------------------*/
    .chat {
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: auto;
    }

    /*--------------------
      Chat Title
      --------------------*/
    .chat-title {
        -webkit-box-flex: 0;
        -webkit-flex: 0 1 45px;
        -ms-flex: 0 1 45px;
        flex: 0 1 45px;
        background-color: #1f2129;
        color: #fff;
        text-align: left;
        padding: 10px 10px 10px 50px;
        border: 1px solid #2e2e38
    }

    .chat-title [data-person="person-avatar"] {
        width: 40px;
        height: 40px;
    }

    .chat-title [data-person="person-avatar"]>img {
        max-width: 100%;
    }

    .chat-title h1,
    .chat-title h2 {
        font-weight: normal;
        font-size: 15px;
        margin: 0;
        padding: 0;
    }

    .chat-title h2 {
        color: rgba(255, 255, 255, 0.5);
        font-size: 8px;
        letter-spacing: 1px;
    }

    /*--------------------
      Messages
      --------------------*/
    .messages {
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        color: rgba(255, 255, 255, 0.5);
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .messages .messages-content {
        height: 100%;
        width: 100%;
        background-color: #1f2129;
    }

    .messages .message {
        clear: both;
        float: left;
        padding: 6px 10px 7px;
        border-radius: 10px 10px 10px 0;
        margin: 2px 0;
        font-size: 13px;
        line-height: 1.4;
        text-shadow: 0 1px 1px rgba(255, 255, 255, 0.2);
    }

    .messages .message .msg-content>span {
        color: #c2c3c4;
        font-size: 16px;
    }

    .messages .message.admin .avatar {
        border: 1px solid #953636;
        background-color: #953636;
    }

    .messages .message .avatar {
        width: 30px;
        height: 30px;
        overflow: hidden;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        text-transform: uppercase;
        margin-left: -5px;
    }

    .messages .message {
        .is-admin {
            color: #48ff00;
            font-weight: bolder;
        }

        .author {
            font-size: 13px;
            color: #ffbc3e !important;
            font-weight: 600;
        }
    }

    .messages .message .avatar img {
        width: 100%;
        height: auto;
    }

    .messages .message .purpil {
        color: #fff;
        background: #5c61ed;
    }

    .messages .message .red {
        color: #fff;
        background: #ed3800;
    }

    .messages .message .author-info {
        height: 20px;
        white-space: nowrap;
    }

    .chat .txt .emoji,
    .chat .txt .authorized {
        cursor: pointer;
    }

    .chat .txt .emoji,
    .chat .txt .authorized,
    .chat .user-login-form .avt,
    .chat .user-login-form .password,
    .chat .user-login-form .cancel-password {
        color: #fff;
        font-size: 27px;
    }

    .chat .user-login-form .cancel-password {
        top: 3px;
        right: 10px;
    }

    .chat .txt .authorized {
        top: 3px;
        right: 80px;
    }

    .chat .txt .emoji,
    .chat .user-login-form .avt,
    .chat .user-login-form .password {
        top: 3px;
        left: 11px;
    }

    .messages .message.message-personal {
        float: right;
        text-align: right;
    }

    .messages .message.message-personal .msg-content {
        margin-left: -38px;

    }

    .messages .message.message-personal .msg-content>span {
        color: #5c61ed;
        border: 1px solid #5c61ed;
        background: #5c61ed29;
    }

    .messages .message:last-child {
        margin-bottom: 30px;
    }

    .messages .message.new {
        color: #fff;
    }

    .messages .admin.message {
        background: #832323;
        text-transform: uppercase;
        font-weight: 600;
    }

    .messages .admin.message::before {
        border-top: 6px solid #832323;
    }

    .messages .message.loading::before {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.5);
        z-index: 2;
        margin-top: 4px;
        -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
        animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
        border: none;
        -webkit-animation-delay: .15s;
        animation-delay: .15s;
    }

    .messages .message.loading span {
        display: block;
        font-size: 0;
        width: 20px;
        height: 10px;
        position: relative;
    }

    .messages .message.loading span::before {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.5);
        z-index: 2;
        margin-top: 4px;
        -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
        animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
        margin-left: -7px;
    }

    .messages .message.loading span::after {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.5);
        z-index: 2;
        margin-top: 4px;
        -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
        animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
        margin-left: 7px;
        -webkit-animation-delay: .3s;
        animation-delay: .3s;
    }

    /*--------------------
      Message Box
      --------------------*/
    .message-box {
        width: 100%;
        background: #1f2129;
        border-top: 1px solid #2e2e38;
    }

    .user-login-wrapper .btn.btn-primary,
    .chat-options .btn.btn-primary {
        min-width: 70px;
        font-size: 18px;
    }

    .message-box div[data-person="person-avatar"] {
        font-size: 18px;
        color: #fff;
        background: #272933;
        width: 40px;
        height: 40px;
    }

    #frm-login input[name="password"]::placeholder,
    #frm-login input[name="username"]::placeholder,
    .message-box .message-input::placeholder {
        color: rgba(255, 255, 255, 0.7);
    }

    #frm-login input[name="username"],
    #frm-login input[name="password"],
    .message-box .message-input {
        background: none;
        border: none;
        outline: none !important;
        resize: none;
        color: rgba(255, 255, 255, 0.7);
        font-size: 15px;
        margin: 0;
        width: 100%;
        background-color: #22232b;
        overflow: hidden;
        box-shadow: none;
    }

    .message-box textarea:focus:-webkit-placeholder {
        color: transparent;
    }

    .user-login-form .btn-login,
    .message-box .message-submit {
        color: #fff;
        border: none;
        background: #5c61ed;
        text-transform: uppercase;
        outline: none !important;
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
    }

    .message-box .message-submit>span {
        margin-right: 10px;
    }

    .message-box .message-submit>span:last-child {
        margin: 0;
    }

    .message-box .message-submit>span>img {
        width: 24px;
    }

    .user-login-form .btn-login:hover,
    .user-login-form .btn-login:active,
    .user-login-form .btn-login:focus,
    .message-box .message-submit:hover,
    .message-box .message-submit:active,
    .message-box .message-submit:focus {
        background: #5c61ed;
    }

    .chat .emoji-wrapper {
        display: none;
    }

    .chat .emoji-wrapper.open {
        display: flex;
        position: absolute;
        height: 400px;
        z-index: 99999;
        bottom: 59px;
        align-items: end;
        justify-content: start;
    }

    .chat .emoji-wrapper>.emoji-window {
        background: #22232b;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }

    .chat .emoji-overlay {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
    }

    .chat .emoji-wrapper .emoji-content {
        overflow: hidden;
        width: 430px;
        height: 410px;
        flex: 1;
    }

    .chat .emoji-wrapper .emoji-content .sticker-item {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .chat .emoji-wrapper .emoji-content .emoji-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
    }

    .chat .emoji-wrapper .emoji-type {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 14px;
        background: rgb(31 33 41);
    }

    .chat .emoji-wrapper .emoji-type>a {
        text-decoration: none;
        flex: 1;
        text-align: center;
        color: #fff;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media only screen and (max-width: 600px) {
        .chat .emoji-wrapper.open {
            right: 0;
            left: 0;
            width: 100%;
            justify-content: center;
        }
    }

    .chat .emoji-wrapper .emoji-content .tab {
        display: none;
    }

    .chat .emoji-wrapper .emoji-content .tab.active {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        padding: 10px;
    }

    .chat .emoji-wrapper .emoji-content .tab>span {
        font-size: 30px;
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .chat .emoji-wrapper .emoji-content .tab>span:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 5px;
    }

    .chat .emoji-wrapper .emoji-type>a.active {
        background: #5c61ed;
    }

    .chat .loading {
        display: none;
    }

    .chat .loading.open {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99999999999 !important;
        background-color: #fff;
    }

    .chat .loading span[role="status"] {
        font-size: 14px;
    }


    .chat .user-login-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /*--------------------
      Custom Srollbar
      --------------------*/
    .mCSB_scrollTools {
        margin: 1px -3px 1px 0;
        opacity: 0;
    }

    .mCSB_inside>.mCSB_container {
        margin-right: 0px;
        padding: 0 10px;
    }

    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }

    /*--------------------
      Bounce
      --------------------*/
    @-webkit-keyframes bounce {
        0% {
            -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        4.7% {
            -webkit-transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        9.41% {
            -webkit-transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        14.11% {
            -webkit-transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        18.72% {
            -webkit-transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        24.32% {
            -webkit-transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        29.93% {
            -webkit-transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        35.54% {
            -webkit-transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        41.04% {
            -webkit-transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        52.15% {
            -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        63.26% {
            -webkit-transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        85.49% {
            -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        100% {
            -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }
    }

    @keyframes bounce {
        0% {
            -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        4.7% {
            -webkit-transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        9.41% {
            -webkit-transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        14.11% {
            -webkit-transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        18.72% {
            -webkit-transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        24.32% {
            -webkit-transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        29.93% {
            -webkit-transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        35.54% {
            -webkit-transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        41.04% {
            -webkit-transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        52.15% {
            -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        63.26% {
            -webkit-transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        85.49% {
            -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        100% {
            -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }
    }

    @-webkit-keyframes ball {
        from {
            -webkit-transform: translateY(0) scaleY(0.8);
            transform: translateY(0) scaleY(0.8);
        }

        to {
            -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
        }
    }

    @keyframes ball {
        from {
            -webkit-transform: translateY(0) scaleY(0.8);
            transform: translateY(0) scaleY(0.8);
        }

        to {
            -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
        }
    }
}